import React from 'react';
import styles from './Footer.module.css';
import linkedIn from '../linkedIn.png';
import git from '../github.png';
const Footer = () => {
  return (
    <div className={styles.container}>
      <h3>Contact Me </h3>
      <h4>
        <span className={styles.symbol}>D</span>ave
        <span className={styles.symbol}>W</span>hite
        <span className={styles.symbol}>P</span>rogrammer@gmail.com
      </h4>
      <h4>717-404-4622</h4>
      <p> &copy; 2023</p>
      <div className={styles.links_container}>
        <a href="https://www.linkedin.com/in/david-white-developer/">
          {' '}
          <img className={styles.linkedIn} src={linkedIn}></img>
        </a>
        <a href="https://github.com/davewhite03">
          {' '}
          <img className={styles.github} src={git}></img>
        </a>
      </div>
    </div>
  );
};

export default Footer;
