import React from 'react';
import styles from './Hamburger.module.css';
import { Link } from 'react-router-dom';
import linkedIn from '../linkedIn.png';
import git from '../github.png';
const Hamburger = (props) => {
  const hello = () => {
    console.log(' this is it!');
    const input = document.getElementById('Hamburger_menuInput__pOGOR');
    input.checked = false;
    console.log(input);
  };
  console.log(props.data);
  return (
    <div className={styles.container}>
      <input type="checkbox" name="hello" id={styles.menuInput} />
      <div className={styles.menu}>
        <ul>
          <li>
            {' '}
            <Link onClick={hello} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link onClick={hello} to="/workouts">
              Web Development
            </Link>
          </li>
          <li>
            {' '}
            <Link onClick={hello} to="/animation">
              Animation
            </Link>
          </li>
          <li>
            {' '}
            <Link onClick={hello} to="/Design">
              Design
            </Link>
          </li>
          <li>
            {' '}
            <a
              onClick={hello}
              href="https://cdn.sanity.io/files/yfuh89u6/production/eee276eb271d2c7993fa90bf919d9776fc4a3f1e.pdf"
            >
              Resume
            </a>
          </li>

          <div className={styles.linkContainer}>
            <a
              onClick={hello}
              href="https://www.linkedin.com/in/david-white-developer/"
            >
              {' '}
              <img className={styles.linkedIn} src={linkedIn}></img>
            </a>
            <a onClick={hello} href="https://github.com/davewhite03">
              {' '}
              <img className={styles.github} src={git}></img>
            </a>
          </div>
        </ul>
      </div>
      <div className={styles.modal}></div>
      <label className={styles.icon} for={styles.menuInput}></label>
    </div>
  );
};

export default Hamburger;
