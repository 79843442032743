import React, { useState, useEffect } from 'react';
import sanityClient from './client';
import './App.css';
import Hamburger from './components/Hamburger';
import Background from './components/Background';
import Workouts from './pages/Workouts';
import { Route, Routes } from 'react-router-dom';
import Animation from './pages/Animation';
import Design from './pages/Design';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

function App() {
  const [data, setData] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "project"] {
      title,
      slug,
     category,
     videoURL,
     skill1,
     skill2,
     skill3,
     imageUrl,
     image,
     websiteURL,
     gitURL,
     
    }`
      )
      .then((data) => setData(data))
      .catch(console.error);
  }, []);

  const website = data.filter((a) => {
    return a.category === 'website';
  });
  const animation = data.filter((a) => {
    return a.category === 'animation';
  });
  const design = data.filter((a) => {
    return a.category === 'design';
  });

  return (
    <div className="App">
      <NavBar />
      <Hamburger />

      <Routes>
        <Route path="/" element={<Background />} />
        <Route path="/workouts" element={<Workouts data={website} />} />
        <Route path="/animation" element={<Animation data={animation} />} />
        <Route path="/Design" element={<Design data={design} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
