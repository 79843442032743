import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import styles from './Slideshow.module.css';
import { urlFor } from '../client';

const slideImages = [
  {
    url: 'images/slide_2.jpg',
    caption: 'Slide 1',
  },
  {
    url: 'images/slide_3.jpg',
    caption: 'Slide 2',
  },
  {
    url: 'images/slide_4.jpg',
    caption: 'Slide 3',
  },
];

const Slideshow = (props) => {
  console.log(props.image);
  return (
    <div className="slide-container">
      <Slide>
        {props.image.map((slideImage, index) => (
          <div className="each-slide" key={index}>
            <div
              className={styles.image}
              style={{ backgroundImage: `url(${slideImage})` }}
            >
              <span></span>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default Slideshow;
