import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export default sanityClient({
  projectId: 'yfuh89u6',
  dataset: 'production',
  apiVersion: '2022-11-27',
  useCdn: 'true',

  token: process.env.NEXT_SANITY_TOKEN,
});
const builder = imageUrlBuilder(sanityClient);

export const urlFor = (source) => builder.image(source);
