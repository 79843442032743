import React from 'react';
import { useState } from 'react';
import Pagination from '../components/Pagination';
import styles from './Animation.module.css';
import VideoLayout from '../components/VideoLayout';

const dummyArray = [
  { title: 'Animation1', url: 'https://www.youtube.com/watch?v=l1oBC3CaelU' },
  { title: 'Animation2', url: 'https://www.youtube.com/watch?v=l1oBC3CaelU' },
  { title: 'Animation3', url: 'https://www.youtube.com/watch?v=l1oBC3CaelU' },
  { title: 'Animation4', url: 'https://www.youtube.com/watch?v=nLQ-9vfEjUI' },
  { title: 'Animation5', url: 'https://www.youtube.com/watch?v=nLQ-9vfEjUI' },
  { title: 'Animation6', url: 'https://www.youtube.com/watch?v=nLQ-9vfEjUI' },
  { title: 'Animation1', url: 'https://www.youtube.com/watch?v=l1oBC3CaelU' },
  { title: 'Animation2', url: 'https://www.youtube.com/watch?v=l1oBC3CaelU' },
  { title: 'Animation3', url: 'https://www.youtube.com/watch?v=l1oBC3CaelU' },
  { title: 'Animation4', url: 'https://www.youtube.com/watch?v=nLQ-9vfEjUI' },
  { title: 'Animation5', url: 'https://www.youtube.com/watch?v=nLQ-9vfEjUI' },
  { title: 'Animation6', url: 'https://www.youtube.com/watch?v=nLQ-9vfEjUI' },
  { title: 'Animation1', url: 'https://www.youtube.com/watch?v=l1oBC3CaelU' },
  { title: 'Animation2', url: 'https://www.youtube.com/watch?v=l1oBC3CaelU' },
  { title: 'Animation3', url: 'https://www.youtube.com/watch?v=l1oBC3CaelU' },
  { title: 'Animation4', url: 'https://www.youtube.com/watch?v=nLQ-9vfEjUI' },
  { title: 'Animation5', url: 'https://www.youtube.com/watch?v=nLQ-9vfEjUI' },
  { title: 'Animation6', url: 'https://www.youtube.com/watch?v=nLQ-9vfEjUI' },
];

const Animation = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = props.data.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(props.data.length / recordsPerPage);

  return (
    <div className={styles.container}>
      <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <ul>
        {currentRecords.map((workOut) => (
          <VideoLayout
            data={workOut}
            websiteURL={workOut.websiteURL}
            git={workOut.gitURL}
            url={workOut.videoURL}
            image={workOut.imageUrl}
            skill1={workOut.skill1}
            skill2={workOut.skill2}
            skill3={workOut.skill3}
          />
        ))}
      </ul>
      <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};
export default Animation;
