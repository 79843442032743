import React from 'react';
import styles from './Background.module.css';
import logo from '../logo.png';
const Background = () => {
  return (
    <div className={styles.background}>
      <img src={logo} alt="logo" className={styles.logo} />

      <h1 className={styles.title}>
        David <span className={styles.lastName}>White</span>
      </h1>
      <h2 className={styles.subTitle}>Development & Design</h2>
      <h3>About Me</h3>
      <h4 className={styles.summary}>
        Professional Applications Programmer with experience creating user
        interfaces to provide clients with a seamless user experience.{' '}
      </h4>
    </div>
  );
};

export default Background;
