import React from 'react';
import styles from './VideoLayout.module.css';
import ReactPlayer from 'react-player';
import git from '../github.png';
import Slideshow from './Slideshow';

const VideoLayout = (props) => {
  const image = props.image;
  const video = props.url;
  console.log(video);
  return (
    <li className={styles.container}>
      {props.data ? <h2 className={styles.title}>{props.data.title}</h2> : ''}
      {video ? (
        <ReactPlayer url={props.url} height="150px" width="300px" />
      ) : (
        ''
      )}

      {image ? <Slideshow image={props.image} /> : ''}

      <div className={styles.innerContainer}>
        <ul>
          <li>{props.skill1}</li>
          <li>{props.skill2}</li>
          <li>{props.skill3}</li>
        </ul>
        <ul className={styles.links}>
          <li>
            <a href={props.git}>
              <img className={styles.git} src={git} alt="" />
            </a>
          </li>
          <li>
            <a className={styles.link} href={props.websiteURL}>
              LINK
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.footer}></div>
    </li>
  );
};

export default VideoLayout;
