import React from 'react';
import styles from './NavBar.module.css';
import { Link } from 'react-router-dom';
import linkedIn from '../linkedIn.png';
import git from '../github.png';
const NavBar = () => {
  return (
    <>
      <div className={styles.container}>
        <ul>
          <li>
            {' '}
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/workouts">Web Development</Link>
          </li>
          <li>
            {' '}
            <Link to="/animation">Animation</Link>
          </li>
          <li>
            {' '}
            <Link to="/Design">Design</Link>
          </li>
          <li>
            {' '}
            <a href="https://cdn.sanity.io/files/yfuh89u6/production/c370911c0b64fc2af20b046bf378d9400f039753.docx">
              Resume
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NavBar;
